import React, { useEffect, useState } from "react";
import FlexibleContent from "../components/reusable/FlexibleContent";
import { graphql } from "gatsby";
import { Link } from "gatsby";
import { Styling } from "../styles/templates/student-success-post.styled";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import parse from "html-react-parser";
import Seo from "../components/global/seo";
import styled from "styled-components";
import { RellaxWrapper } from "react-rellax-wrapper";

gsap.registerPlugin(ScrollTrigger);

const ImageFocalPoint = styled.div`
  img {
    object-position: ${(props) => props.x}% ${(props) => props.y}%;
  }
`;

export default function StudentSuccessPost({ data }) {
  const [image, setImage] = useState({
    heroType: "image",
    heroSrc:
      data?.testimonialInfo?.testimonialFields?.profileImage?.localFile
        ?.publicURL,
  });

  useEffect(() => {
    let hero = null;
    let pinSpacer = null;

    // Create Dynamic Video
    if (hero === null) {
      const body = document.querySelector("body");
      hero = document.createElement("div");
      const imageEl = document.createElement("img");
      hero.classList.add("testimonials-hero");
      hero.classList.add("offscreen-translator");
      imageEl.setAttribute("src", image.heroSrc);

      if (
        data?.testimonialInfo?.testimonialFields?.customFocalPoint?.x &&
        data?.testimonialInfo?.testimonialFields?.customFocalPoint?.y
      ) {
        imageEl.style.objectPosition = `${data?.testimonialInfo?.testimonialFields?.customFocalPoint?.x}% ${data?.testimonialInfo?.testimonialFields?.customFocalPoint?.y}%`;
      }

      body.appendChild(hero);
      hero.appendChild(imageEl);
      ScrollTrigger.create({
        trigger: ".scroll-content",
        pin: ".testimonials-hero",
        start: "top top",
        end: "bottom bottom",
        pinSpacing: false,
      });
    }

    return () => {
      hero.remove();
      if (typeof window !== "undefined") {
        const spacers = document.querySelectorAll(".pin-spacer");
        spacers.forEach((spacer) => spacer.remove());
      }
    };
  }, [image]);

  const cta = data.ctaProgramme || data.ctaCourse;
  let ctaTitle;
  let ctaText;
  let ctaLink;

  if (typeof cta.courseBuilder !== "undefined") {
    ctaTitle = cta.title;
    ctaText = parse(cta.courseBuilder.briefDescription);
    ctaLink = `/programmes/on-demand/${cta.slug}`;
  } else {
    ctaTitle = cta.title;
    // ctaText = cta.interactive_programme.archiveFieldsCopy || cta.dj_programme.archiveFieldsCopy
    ctaText = cta.interactive_programme.archiveFieldsCopy;
    ctaLink = `/programmes/${cta.programmeCategories.nodes[0].slug}/${cta.slug}`;
  }

  return (
    <Styling>
      <Seo
        title={`${data.testimonialInfo.title} | Student Success`}
        description={data.testimonialInfo.seo.metaDesc}
      />
      <div className="scroll-content">
        <RellaxWrapper speed={-9}>
          <ImageFocalPoint
            x={
              data?.testimonialInfo?.testimonialFields
                ?.profileImageFocalPoint === "custom"
                ? data?.testimonialInfo?.testimonialFields?.customFocalPoint.x
                : ""
            }
            y={
              data?.testimonialInfo?.testimonialFields
                ?.profileImageFocalPoint === "custom"
                ? data?.testimonialInfo?.testimonialFields?.customFocalPoint.y
                : ""
            }
          >
            <GatsbyImage
              className={`mobileImg ${data?.testimonialInfo?.testimonialFields?.profileImageFocalPoint}`}
              image={getImage(
                data?.testimonialInfo?.testimonialFields?.profileImage
                  ?.localFile
              )}
              alt={data.testimonialInfo.title}
            />
          </ImageFocalPoint>
        </RellaxWrapper>
        <div className="testiIntro">
          <h2 className="main-title">{data.testimonialInfo.title}</h2>
          <div className="course">
            {data.testimonialInfo.testimonialFields
              .courseCompletedCustomOverride
              ? data.testimonialInfo.testimonialFields
                  .courseCompletedCustomOverride
              : data.testimonialInfo.testimonialFields.courseCompleted.title}
          </div>
        </div>
        <FlexibleContent flexible={data.flexible} />
        <div className="cta">
          <div className="cta-inner">
            <div className="title">{ctaTitle}</div>
            <div className="text">{ctaText}</div>
            <Link to={ctaLink} className="capsule_button black">
              Learn more
            </Link>
          </div>
        </div>
      </div>
    </Styling>
  );
}

export const query = graphql`
  query ($id: String!, $course: String!) {
    ctaProgramme: wpProgramme(title: { eq: $course }) {
      title
      slug
      interactive_programme {
        archiveFieldsCopy
      }

      programmeCategories {
        nodes {
          slug
        }
      }
    }
    ctaCourse: wpCourse(title: { eq: $course }) {
      title
      slug
      courseBuilder {
        briefDescription
      }
    }
    testimonialInfo: wpTestimonial(id: { eq: $id }) {
      seo {
        title
        metaDesc
      }
      title
      testimonialFields {
        archiveQuote
        courseCompletedCustomOverride
        courseCompleted {
          ... on WpCourse {
            title
          }
          ... on WpProgramme {
            title
          }
        }
        profileImageFocalPoint
        customFocalPoint {
          x
          y
        }
        profileImage {
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(
                width: 1500
                placeholder: BLURRED
                formats: [AUTO, WEBP]
                layout: FULL_WIDTH
              )
            }
          }
        }
      }
    }
    flexible: wpTestimonial(id: { eq: $id }) {
      flexibleContent {
        postBuilder {
          ... on WpTestimonial_Flexiblecontent_PostBuilder_TextBlock {
            fieldGroupName
            text
          }
          ... on WpTestimonial_Flexiblecontent_PostBuilder_Quote {
            fieldGroupName
            quoteText
          }
          ... on WpTestimonial_Flexiblecontent_PostBuilder_AudioPlayer {
            fieldGroupName
            trackTile
            audioFile {
              localFile {
                publicURL
              }
            }
          }
          ... on WpTestimonial_Flexiblecontent_PostBuilder_ImageBlock {
            fieldGroupName
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 1500
                    placeholder: BLURRED
                    formats: [AUTO, WEBP]
                    layout: FULL_WIDTH
                  )
                }
              }
            }
          }
          ... on WpTestimonial_Flexiblecontent_PostBuilder_VideoBlock {
            fieldGroupName
            video
          }
        }
      }
    }
  }
`;
